
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import affTicket from "@/views/still/deploy/affTicket.vue";
import chat from "@/views/still/chat/chat.vue";
import syntheseTicket from "@/views/still/deploy/syntheseTicket.vue";
import planningTicket from "@/views/still/deploy/planningTicket.vue";
import MapPresta from "@/views/still/fournisseur/mapPresta.vue";
import { useI18n } from "vue-i18n";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    affTicket,
    chat,
    syntheseTicket,
    planningTicket,
    MapPresta
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {

    
    const { t } = useI18n()

    //moment.default.locale("fr");
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
      ticketSelect: {},
      valTicket: {},
      selTicket : 0,
      topChat : 95,
      hauteurChat: 124,
      type_stat : 'tic_c_status',
      compteur : 0,
      colors:['33b1f9','fd9800','8fbf30','cd0067','6601ff','006766','0000cc','019934','fe0000','33b1f9','fd9800','8fbf30','cd0067','6601ff','006766','0000cc','019934','fe0000'],
      affMap : false,
    });

    const tableHeader = [
      {
        name: "Ticket",
        key: "tic_seq",
        sortable: true,
      },
      {
        name: "Création",
        key: "tic_d_creation",
        sortable: true,
      },
      {
        name: "Statut",
        key: "tic_c_status",
        sortable: true,
      },
    ];

    const tableFilters = ref([
      {
        name: "Statut",
        key: "tic_c_status",
        label: "tic_c_status",
      },
      {
        name: "Services",
        key: "services",
        label: "services",
      },
      {
        name: "Demandeur",
        key: "con_prenom_nom",
        label: "con_prenom_nom",
      },
    ]);

    const activeName = ref('first')

    const ExcelFormat = ref({
      "Ticket": "tic_seq",
      "Création": "tic_d_creation",  
      "Libellé du ticket": "tic_c_label",
      "Demandeur": "con_prenom_nom",
      "Statut": "tic_c_status",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const affTicket = (data) => {
      affDetail(data.tic_seq);
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const affDetail = async (data) => {
      
      state.selTicket = data;

      let myList = await mAxiosApi.prototype.getAxios("/getTicket/" + data);
      myList.results.tic_staging.forEach((el) => {
        el.fichiers = JSON.parse(el.fichiers);
      })
      state.valTicket = myList.results;    
      state.drawer = true;
    };

    const handleClickPane = () => {
      
      if (activeName.value=='third') {
        setTimeout(() => {
          state.affMap = activeName.value=='third'
        }, 500)
      } else {
        state.affMap = false;
      }
 
    }


    const styleImgStatus = (data) => {
      if (t(data.tic_c_status) == t("Traité")) {
        return "media/icons/duotune/arrows/arr085.svg";
      } else {
        return "media/icons/duotune/general/gen012.svg";
        
      }
    };

    const styleBtnStatus = (data) => {
      if (t(data.tic_c_status) == t("Traité")) {
        return "btn-icon-success bg-white";
      } else {
         return "btn-icon-dark bg-white svg-icon svg-icon-sm-6 ";
      }
    };



    onMounted(async () => {
      MenuComponent.reinitialization();

      let myList = await mAxiosApi.prototype.getAxios("/getTickets");
      state.initialMyList = myList.results;
      state.myList = myList.results;      
      // console.log(state.myList);

      if (state.myList.length > 0){
        if (router.currentRoute.value.params.ticid) {
          affDetail(router.currentRoute.value.params.ticid);
        }
        if (router.currentRoute.value.params.status) {
          //alert(router.currentRoute.value.params.status);
          tableFilters.value.filter(el => el.key=="tic_c_status")[0]['default'] = [router.currentRoute.value.params.status];
        }

        if(state.myList.filter(el => el.tis_date_planif != '' && el.tis_date_planif != null).length > 0) {
          tableHeader.push({
            name: "Planification",
            key: "tis_date_planif",
            sortable: true,
          });
        }
        if(state.myList.filter(el => el.liv_date_liv != '' && el.liv_date_liv != null).length > 0) {
          tableHeader.push({
            name: "Expédition",
            key: "liv_date_liv",
            sortable: true,
          });
        }

        tableHeader.push({
          name: "Actions",
          key: "tic_knum_societe",
          sortable: true,
        });


      }

      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      tableFilters,
      ExcelFormat,
      refreshRouter,
      styleImgStatus,
      styleBtnStatus,
      activeName,
      affTicket,
      handleClickPane
    };
  },
});
